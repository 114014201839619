export const SET_PAGE = "SET_PAGE"



export const accountPageReducer = (state, action) => {
    switch(action.type) {
        case SET_PAGE:
            state.page = action.payload
            return
    }
}