import countries from '../i18n/countries.json'
export const CHANGE_COMPANY_NAME = "CHANGE_COMPANY_NAME"
export const CHANGE_CLIENT_FULLNAME = "CHANGE_CLIENT_FULLNAME"
export const CHANGE_CLIENT_TYPE = "CHANGE_CLIENT_TYPE"
export const CHANGE_AGENT_CODE = "CHANGE_AGENT_CODE"
export const CHANGE_CONTACT_EMAIL = "CHANGE_CONTACT_EMAIL"
export const CHANGE_CONTACT_COUNTRY = "CHANGE_CONTACT_COUNTRY"
export const CHANGE_CONTACT_PHONE = "CHANGE_CONTACT_PHONE"
export const CHANGE_PRIVATE_CLIENT_NIF = "CHANGE_PRIVATE_CLIENT_NIF"
export const CHANGE_PRIVATE_CLIENT_VAT = "CHANGE_PRIVATE_CLIENT_VAT"
export const CHANGE_CONTACT_ADDRESS = "CHANGE_CONTACT_ADDRESS"
export const CHANGE_CONTACT_POSTALCODE = "CHANGE_CONTACT_POSTALCODE"
export const CHANGE_CONTACT_CITY = "CHANGE_CONTACT_CITY"
export const CLIENT_CLEAN_FORM = "CLIENT_CLEAN_FORM"


export const clientInfoReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_COMPANY_NAME:
            state.clientInfo.companyName = action.payload
            return
        case CHANGE_CLIENT_FULLNAME:
            state.clientInfo.fullName = action.payload
            return
        case CHANGE_CLIENT_TYPE:
            state.clientInfo.type = action.payload
            return
        case CHANGE_AGENT_CODE:
            state.clientInfo.agentCode = action.payload
            return
        case CHANGE_CONTACT_EMAIL:
            state.contactInfo.email = action.payload
            return
        case CHANGE_CONTACT_COUNTRY:
            state.contactInfo.country = action.payload
            const country = countries.filter( c => c.name === action.payload )
            state.contactInfo.phoneCountryCode = country[0].phoneCode
            return
        case CHANGE_CONTACT_PHONE:
            state.contactInfo.phone = action.payload
            return
        case CHANGE_PRIVATE_CLIENT_NIF:
            state.clientInfo.nif = action.payload
            return
        case CHANGE_PRIVATE_CLIENT_VAT:
            state.clientInfo.vat = action.payload
            return
        case CHANGE_CONTACT_ADDRESS:
            state.contactInfo.address = action.payload
            return
        case CHANGE_CONTACT_POSTALCODE:
            state.contactInfo.postalcode1 = action.payload
            return
        case CHANGE_CONTACT_CITY:
            state.contactInfo.city = action.payload
            return
        case CLIENT_CLEAN_FORM:
            state.clientInfo = {
                    type: "singular",
                    companyName: "",
                    fullName: "",
                    nif: "",
                    vat: "",
                    agentCode: "",
                }
            state.contactInfo = {
                    address: "",
                    postalcode1: "",
                    postalcode2: "",
                    city: "",
                    country: "Portugal",
                    email: "",
                    phone: "",
                    phoneCountryCode: "+351",
                }
            
            return
    }
}