import countries from '../i18n/countries.json'
export const SET_NAVIGATION_VISIBILITY = "SET_NAVIGATION_VISIBILITY"
export const SET_PROGRESSBAR_VISIBILITY = "SET_PROGRESSBAR_VISIBILITY"
export const RESET_FORM = "RESET_FORM"
export const SET_NEWREG_ID = "SET_NEWREG_ID"
export const SET_NEWREG_CREATED = "SET_NEWREG_CREATED"


export const regLayoutReducer = (state, action) => {
    switch (action.type) {
        case SET_NAVIGATION_VISIBILITY:
            state.isNavigationVisible = action.payload;
            return;
        case SET_PROGRESSBAR_VISIBILITY:
            state.isProgressBarVisible = action.payload;
            return;
        case RESET_FORM:
            state.resetForm = action.payload;
            return;
        case SET_NEWREG_ID:
            state.newRegFirebaseId = action.payload;
            return
        case SET_NEWREG_CREATED:
            state.newRegCreated = action.payload
            return
    }
}