export const ADD_CARD = "ADD_CARD"
export const REMOVE_CARD = "REMOVE_CARD"
export const CHANGE_CARD_CLIENT_NAME = "CHANGE_CARD_CLIENT_NAME"
export const CHANGE_CARD_LICENCE_PLATE = "CHANGE_CARD_LICENCE_PLATE"
export const CHANGE_CARD_CAR_BRAND = "CHANGE_CARD_CAR_BRAND"
export const CHANGE_CARD_CAR_MODEL = "CHANGE_CARD_CAR_MODEL"
export const CARDS_CLEAN_FORM = "CARDS_CLEAN_FORM"



export const cardsReducer = (state, action) => {
    switch (action.type) {
        case ADD_CARD:
            state.push({
                id: state[state.length-1].id+1,
                name: "",
                licencePlate: "",
                brand: "",
                model: ""
            })    
            return
        case REMOVE_CARD:
            if(state.length>1)
            state.splice(action.payload.index, 1)
            return
        case CHANGE_CARD_CLIENT_NAME:
            state[action.payload.index].name = action.payload.value
            return
        case CHANGE_CARD_LICENCE_PLATE:
            state[action.payload.index].licencePlate = action.payload.value
            return
        case CHANGE_CARD_CAR_BRAND:
            state[action.payload.index].brand = action.payload.value
            return
        case CHANGE_CARD_CAR_MODEL:
            state[action.payload.index].model = action.payload.value
            return
        case CARDS_CLEAN_FORM:
            state.length = 0
            state.push({
                id: 0,
                name: "",
                licencePlate: "",
                brand: "",
                model: ""
              }
            )
            return
    }
}