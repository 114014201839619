const i18nPT = require("../i18n/i18n-pt.json");
const i18nEN = require("../i18n/i18n-en.json");
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_I18N_FILE = "SET_I18N_FILE";
export const SET_USER = "SET_USER";


const set_i18n_file = (state, payload) => {
    let newState = {...state};
    let i18n;
    if (payload.language === "PT") {
        i18n = i18nPT;
    }
    if (payload.language === "EN") {
        i18n = i18nEN;
    }
    newState.i18n = i18n;
    return newState;
}


export const appReducer = (state, action) => {
    switch (action.type) {
        case SET_LANGUAGE: 
            return {...state, language: action.payload.language}
        case SET_I18N_FILE: 
            return set_i18n_file(state, action.payload);
        case SET_USER: 
            return {...state, user: action.payload}
        default:
            return state;
    }
}