export const CHANGE_TERMS_CONFIRMED = "CHANGE_TERMS_CONFIRMED"
export const CHANGE_PRIVACY_POLICY_CONFIRMED = "CHANGE_PRIVACY_POLICY_CONFIRMED"
export const CHANGE_MOBIE_SHARING_CONFIRMED = "CHANGE_MOBIE_SHARING_CONFIRMED"
export const CHANGE_PARTNERS_SHARING_CONFIRMED = "CHANGE_PARTNERS_SHARING_CONFIRMED"
export const CONFIRMATION_CLEAN_FORM = "CONFIRMATION_CLEAN_FORM"



export const confirmationReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_TERMS_CONFIRMED:
            state.confirmed = action.payload
            return
        case CHANGE_PRIVACY_POLICY_CONFIRMED:
            state.privacyPolicyAgreed = action.payload
            return
        case CHANGE_MOBIE_SHARING_CONFIRMED:
            state.shareDataWithMobie = action.payload
            return
        case CHANGE_PARTNERS_SHARING_CONFIRMED:
            state.shareForPromotions = action.payload
            return
        case CONFIRMATION_CLEAN_FORM:
            state.shareDataWithMobie = false
            state.shareForPromotions = false
            state.privacyPolicyAgreed = false
            state.confirmed = false
            return
    }
}