

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { ThemeProvider } from 'styled-components';

const React = require('react');
const i18n = require("./src/i18n/i18n-pt.json")
const { trackCustomEvent } = require('gatsby-plugin-google-analytics')
const { useReducer, useState } = require("react")

const { AppContext } = require("./src/contexts/AppContext")
const { RegistrationContext } = require('./src/contexts/RegistrationContext')
const { UserContext } = require('./src/contexts/UserContext')
const { AccountPageContext } = require('./src/contexts/AccountPageContext')


const { useImmerReducer } = require('use-immer')
const { appReducer } = require("./src/reducers/AppReducer")
const { clientInfoReducer } = require('./src/reducers/ClientInfoReducer')
const { pageNavigationReducer } = require('./src/reducers/PageNavigatioReducer')
const { plansReducer } = require('./src/reducers/PlansReducer')
const { paymentInfoReducer } = require('./src/reducers/PaymentInfoReducer')
const { cardsReducer } = require('./src/reducers/CardsReducer')
const { confirmationReducer } = require('./src/reducers/ConfirmationReducer');
const { regLayoutReducer } = require('./src/reducers/RegLayoutReducer');
const { accountPageReducer } = require('./src/reducers/AccountPageReducer')
const { userReducer } = require('./src/reducers/UserReducer')
const theme = require('./src/styles/theme')

console.log(process.env.GATSBY_ENVIRONMENT)

// You can delete this file if you're not using it

export const GatsbyWrapper = ({ element }) => {


    const defaultState = {
        language: "PT",
        i18n: i18n,
        user: null
    }
    const [state, dispatch] = useReducer(appReducer, defaultState)
    const appState = state;
    const sendEvent = (category, action, label) => {
        trackCustomEvent(
            {
                category: category,
                action: action,
                label: label
            })
    }

// ##################################################### REGISTER PAGE START #######################################################


    const [pages, pagesDispatch] = useImmerReducer(pageNavigationReducer, {
        currentPage: 0,
        pages: [
            { key: "client", id: "clientInfo", name: "clientInfo" },
            { key: "plan", id: "plan", name: "plan" },
            { key: "payment", id: "paymentInfo", name: "paymentInfo" },
            { key: "cards", id: "cards_title", name: "cards_title" },
            { key: "confirmation", id: "confirmation", name: "confirmation" },
        ]
    })


    const [clientInfoState, dispatchClientInfo] = useImmerReducer(clientInfoReducer, {
        clientInfo: {
            type: "singular",
            companyName: "",
            fullName: "",
            nif: "",
            vat: "",
            agentCode: "",
        },
        contactInfo: {
            address: "",
            postalcode1: "",
            postalcode2: "",
            city: "",
            country: "Portugal",
            email: "",
            phone: "",
            phoneCountryCode: "+351",
        }
    })

    const [plansState, dispatchPlans] = useImmerReducer(plansReducer, {
        type: "",
        energyCycle: "daily",
        alreadyAlfaClient: null,
    })

    const [paymentState, dispatchPaymentState] = useImmerReducer(paymentInfoReducer, {
        iban: "",
        directDebitAgreed: false
    })
    const [cardsState, dispatchCardsState] = useImmerReducer(cardsReducer, [{
        id: 0,
        name: "",
        licencePlate: "",
        brand: "",
        model: ""
    },
    ])
    const [confirmationState, dispatchConfirmationState] = useImmerReducer(confirmationReducer, {
        shareDataWithMobie: false,
        shareForPromotions: false,
        privacyPolicyAgreed: false,
        confirmed: false,
    })        // const [currentPage, setCurrentPage] = useState(0)

    const [regLayout, dispatchRegLayout] = useImmerReducer(regLayoutReducer, {
        isNavigationVisible: true,
        isProgressBarVisible: true,
        newRegFirebaseId: "",
        newRegCreated: false,
    })

    const [errors, setErrors] = useState({
        fullName: null,
        companyName: null,
        agentCode: null,
        email: null,
        phone: null,
        nif: null,
        address: null,
        postalcode1: null,
        city: null,
        plan: null,
        alreadyAlfaClient: null,
        iban: null,
        directDebitAgreed: null,
        cards: [{
            name: null
        }],
        privacyPolicy: null,
        confirmation: null
    });


    const parameters = {
        clientInfo: [clientInfoState, dispatchClientInfo],
        plans: [plansState, dispatchPlans],
        payment: [paymentState, dispatchPaymentState],
        cards: [cardsState, dispatchCardsState],
        confirmation: [confirmationState, dispatchConfirmationState],
        regLayout: [regLayout, dispatchRegLayout],
        pages: [pages, pagesDispatch],
        errors: [errors, setErrors]
    }

    // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ REGISTER PAGE FINISH $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$


    const [account, accountDispatch] = useImmerReducer(accountPageReducer, {
        page: "Consumos"
    })

    const [user, userDispatch] = useImmerReducer(userReducer, {})




    return (
        <AppContext.Provider value={{ appState, dispatch, sendEvent }}>
            <UserContext.Provider value={{ userContext: [user, userDispatch] }}>
                <AccountPageContext.Provider value={{ accountContext: [account, accountDispatch] }}>
                    <RegistrationContext.Provider value={parameters}>
                        <ThemeProvider theme={theme}>
                            {element}
                        </ThemeProvider>
                    </RegistrationContext.Provider>
                </AccountPageContext.Provider>
            </UserContext.Provider>
        </AppContext.Provider>
    )
}