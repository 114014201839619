// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-faqs-index-js": () => import("./../../../src/pages/about/faqs/index.js" /* webpackChunkName: "component---src-pages-about-faqs-index-js" */),
  "component---src-pages-about-our-mission-index-js": () => import("./../../../src/pages/about/our-mission/index.js" /* webpackChunkName: "component---src-pages-about-our-mission-index-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-cartao-carregamento-carro-eletrico-index-js": () => import("./../../../src/pages/cartao-carregamento-carro-eletrico/index.js" /* webpackChunkName: "component---src-pages-cartao-carregamento-carro-eletrico-index-js" */),
  "component---src-pages-how-advantages-vs-fossil-index-js": () => import("./../../../src/pages/how/advantages-vs-fossil/index.js" /* webpackChunkName: "component---src-pages-how-advantages-vs-fossil-index-js" */),
  "component---src-pages-how-circular-economy-index-js": () => import("./../../../src/pages/how/circular-economy/index.js" /* webpackChunkName: "component---src-pages-how-circular-economy-index-js" */),
  "component---src-pages-how-invoice-in-detail-index-js": () => import("./../../../src/pages/how/invoice-in-detail/index.js" /* webpackChunkName: "component---src-pages-how-invoice-in-detail-index-js" */),
  "component---src-pages-how-private-charging-index-js": () => import("./../../../src/pages/how/private-charging/index.js" /* webpackChunkName: "component---src-pages-how-private-charging-index-js" */),
  "component---src-pages-how-public-charging-index-js": () => import("./../../../src/pages/how/public-charging/index.js" /* webpackChunkName: "component---src-pages-how-public-charging-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-login-reset-index-js": () => import("./../../../src/pages/login/reset/index.js" /* webpackChunkName: "component---src-pages-login-reset-index-js" */),
  "component---src-pages-plans-index-js": () => import("./../../../src/pages/plans/index.js" /* webpackChunkName: "component---src-pages-plans-index-js" */),
  "component---src-pages-plans-old-index-js": () => import("./../../../src/pages/plans_old/index.js" /* webpackChunkName: "component---src-pages-plans-old-index-js" */),
  "component---src-pages-powerpacks-carregamento-eletrico-casa-index-js": () => import("./../../../src/pages/powerpacks-carregamento-eletrico-casa/index.js" /* webpackChunkName: "component---src-pages-powerpacks-carregamento-eletrico-casa-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-solutions-plans-index-js": () => import("./../../../src/pages/solutions/plans/index.js" /* webpackChunkName: "component---src-pages-solutions-plans-index-js" */),
  "component---src-pages-solutions-plans-mobility-index-js": () => import("./../../../src/pages/solutions/plans/mobility/index.js" /* webpackChunkName: "component---src-pages-solutions-plans-mobility-index-js" */),
  "component---src-pages-solutions-plans-residential-index-js": () => import("./../../../src/pages/solutions/plans/residential/index.js" /* webpackChunkName: "component---src-pages-solutions-plans-residential-index-js" */),
  "component---src-pages-solutions-private-powerpack-index-js": () => import("./../../../src/pages/solutions/private/powerpack/index.js" /* webpackChunkName: "component---src-pages-solutions-private-powerpack-index-js" */)
}

