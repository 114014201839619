import countries from '../i18n/countries.json'
export const CHANGE_SELECTED_PLAN = "CHANGE_SELECTED_PLAN"
export const CHANGE_ENERGY_CYCLE = "CHANGE_ENERGY_CYCLE"
export const CHANGE_IS_ALFA_CLIENT = "CHANGE_IS_ALFA_CLIENT"
export const PLANS_CLEAN_FORM = "PLANS_CLEAN_FORM"


export const plansReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_SELECTED_PLAN:
            state.type = action.payload
            return
        case CHANGE_ENERGY_CYCLE:
            state.energyCycle = action.payload
            return
        case CHANGE_IS_ALFA_CLIENT:
            state.alreadyAlfaClient = action.payload
            return
        case PLANS_CLEAN_FORM:
            state.type =  ""
            state.energyCycle =  "daily"
            state.alreadyAlfaClient =  null
            return
    }
}