export const INCREASE_PAGE = "INCREASE_PAGE"
export const DECREASE_PAGE = "DECREASE_PAGE"
export const SET_PAGE = "SET_PAGE"
export const PAGE_CLEAN_FORM = "PAGE_CLEAN_FORM"


export const pageNavigationReducer = (state, action) => {
    switch(action.type) {
        case INCREASE_PAGE:
            if (state.currentPage < state.pages.length - 1) {
                ++state.currentPage
            }
            return
        case DECREASE_PAGE:
            if (state.currentPage > 0) {
                --state.currentPage
            }
            return
        case SET_PAGE: {
            state.currentPage = action.payload
            return
        }
        case PAGE_CLEAN_FORM: {
            state.currentPage = 0
            return
        }
    }
}