export const SET_USER_DATA = "SET_USER_DATA"



export const userReducer = (state, action) => {
    switch(action.type) {
        case SET_USER_DATA:
            state = action.payload
            return
    }
}