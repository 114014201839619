export default {
    fonts: {
        family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
    },
    colors: {
        primary: "#A5D65D",
        secondary : "white",
        veryLightGrey: "#F7F7F7",
        lightGrey: "#cccccc",
        lightMediumGrey: "#afafaf",
        mediumGrey: "#919191",
        darkGrey: "#2b2b2b",
        teal: "#00a589",
        purple: "#2E3192",
        darkTeal: "#22B573",
        headerMenu: "#F9F9F9",
        plans: {
            move: "#A5D65D",
            volt: "#5EC6F3",
            home: "#5668DF",
            conditionsBG: "#F2F2F2"
        }
    },
    fontSize: {
        default: "12pt",
        heroTitle: "40pt",
        heroSubtitle: "25pt",
        sectionTitle: "35pt",
        sectionSubtitle: "20pt",
        sectionDescription: "16pt",
        button: "10.2pt", 
    },
    fontWeight: {
        sectionSubtitle: "300",
    },
    text: {
        descriptionLineHeight: "21pt",
    },
    geometry: {
        cardsBorderRadius :"20px",
        btnBorderRadius: "4px",
    }
}

export const shadow = `
    -webkit-box-shadow: 6px 11px 0 #e3e3e3, 
    -6px -6px 11px 0 #ffffff !important;
    -moz-box-shadow: 6px 6px 11px 0 #e3e3e3, 
    -6px -6px 11px 0 #ffffff !important;
    box-shadow:  6px 6px 11px #e3e3e3, 
    -6px -6px 11px #ffffff !important;
`