export const CHANGE_IBAN = "CHANGE_IBAN"
export const CHANGE_DIRECT_DEBIT_AGREED = "CHANGE_DIRECT_DEBIT_AGREED"
export const PAYMENT_CLEAN_FORM = "PAYMENT_CLEAN_FORM"



export const paymentInfoReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_IBAN:
            state.iban = action.payload
            return
        case CHANGE_DIRECT_DEBIT_AGREED:
            state.directDebitAgreed = action.payload
            return
        case PAYMENT_CLEAN_FORM:
            state.iban = ""
            state.directDebitAgreed = false
            return
    }
}